require('./bootstrap');

// Google Analytics
window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'UA-137491706-1', {
    cookie_flags: 'SameSite=None;Secure'
});

// Website
$(document).ready(function () {
    if ($(document).width() < 768) {
        $('#sidebarCollapse span').text('');
    }
    if ($(document).width() > 768) {
        $('#sidebarCollapse span').text('Menú');
    }

    $('.overlay').on('click', function () {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
        $("#dehaze").toggleClass('d-none');
        $("#close").toggleClass("d-none");
    });

    $('#sidebarCollapse').on('click', function () {
        $('.overlay').toggleClass('active');
        $("#dehaze").toggleClass('d-none');
        $("#close").toggleClass("d-none");
        $('#sidebar').toggleClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });
});

$(window).resize(function () {
    if ($(document).width() < 768) {
        $('#sidebarCollapse span').text('');
    }
    if ($(document).width() > 768) {
        $('#sidebarCollapse span').text('Menú');
    }
});
